:root {
  --redDefault: #bf4040;
  --hoverRedDefault: #a12828;
  --greenDefault: #2b8128;
  --hoverGreenDefault: #1d681a;
  --orangeDefault: #db5000;
  --hoverOrangeDefault: #c44800;
}

.backColorRed {
  background-color: var(--redDefault) !important;
}

.backColorGreen {
  background-color: var(--greenDefault) !important;
}

.buttonRed {
  background-color: var(--redDefault) !important;
}

.buttonRedOutline {
  border-color: var(--redDefault) !important;
  color: var(--redDefault) !important;
}

.buttonGreen {
  background-color: var(--greenDefault) !important;
}

.buttonGreen:hover {
  background-color: var(--hoverGreenDefault) !important;
}

.buttonOrange {
  background-color: var(--orangeDefault) !important;
}

.buttonOrange:hover {
  background-color: var(--hoverOrangeDefault) !important;
}

.buttonOpenRed {
  color: #f44336 !important;
  border-color: #f4433614 !important;
  background-color: #f4433614 !important;
  cursor: pointer;
}

.buttonOpenRed:hover {
  background-color: #f4433644 !important;
}

.buttonOpenBlue {
  color: #4682b4 !important;
  border-color: #4682b414 !important;
  background-color: #4682b414 !important;
  cursor: pointer;
}

.buttonOpenBlue:hover {
  background-color: #4682b444 !important;
}

.buttonOpenGreen {
  color: var(--greenDefault) !important;
  border-color: #4ab44614 !important;
  background-color: #4ab44614 !important;
  cursor: pointer;
}

.buttonOpenGreen:hover {
  background-color: #4ab44644 !important;
}

.buttonOpenOrange {
  color: var(--orangeDefault) !important;
  border-color: #db500014 !important;
  background-color: #db500014 !important;
  cursor: pointer;
}

.buttonOpenOrange:hover {
  background-color: #db500044 !important;
}

.buttonOutGreen {
  background-color: transparent !important;
  border-color: var(--greenDefault) !important;
  color: var(--greenDefault) !important;
}

.buttonOutGreen:hover {
  border-color: var(--hoverGreenDefault) !important;
  color: var(--hoverGreenDefault) !important;
}
